const axios = require("axios");

const SET_MAGAZINE = "editor/SET_MAGAZINE";
const SET_MOUSE = "editor/SET_MOUSE";
const SET_OBJECT = "editor/SET_OBJECT";
const REMOVE_OBJECT = "editor/REMOVE_OBJECT";
const TOGGLE_MODAL_FILES = "editor/TOGGLE_MODAL_FILES";
const SET_PAGE = "editor/SET_PAGE";
const REMOVE_PAGE = "editor/REMOVE_PAGE";

const initialState = {
  magazine: "",
  page: "",
  pages: [],
  object: null,
  elements: [],
  mousePressed: false,
  selected: false,
  popover: false,
  modalFiles: false,
  saving: true,
};

var load = false;

export default function editor(state = initialState, action) {
  switch (action.type) {
    case SET_MOUSE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_OBJECT:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE_OBJECT:
      return {
        ...state,
        ...action.payload,
      };
    case TOGGLE_MODAL_FILES:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE_PAGE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_MAGAZINE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

const pixelToPercentageX = (pixels) => {
  var contentWidth = document.querySelector(".editor-image-area > img").width;
  var percentage = (pixels / contentWidth) * 100;
  return percentage;
};

const pixelToPercentageY = (pixels) => {
  var contentHeight = document.querySelector(".editor-image-area > img").height;
  // alert(`pixels ${pixels}, contentHeight ${contentHeight}`)
  var percentage = (pixels / contentHeight) * 100;
  return percentage;
};

export const setMagazine = (magazine) => (dispatch) => {
  dispatch({ type: SET_PAGE, payload: { saving: true } });
  axios
    .get(`/magazines/${magazine}/`)
    .then(function (response) {
      console.log("response", response.data);
      dispatch({
        type: SET_PAGE,
        payload: {
          magazine: response.data.id,
          pages: JSON.parse(response.data.pages),
        },
        saving: false,
      });
    })
    .catch(function (error) {
      alert("Error, " + error);
      dispatch({ type: SET_PAGE, payload: { saving: false } });
    });
};

const _getMagazine = (magazine, page, dispatch, getState) => {
  let pages = getState().editor.pages;
  // dispatch({ type: SET_PAGE, payload: {saving: true } });
  axios
    .get(`/revista/?magazine=${magazine}&page=${page}`)
    .then(function (response) {
      // pages.push(response.data)
      // pages.sort(function(a, b){
      // 	return a.id - b.id;
      // });

      window
        .$("body")
        .append(
          `<img src="${response.data.url}" class="magazine-image-size" />`
        );

      if (!load && window.$(window).width() > 1100) {
        window.$("#flipbook").turn("removePage", 1);
        load = true;
      }

      let elements = `
				<div
					style="background-image: url('${response.data.url}');"
					class="magazine-image"
				>
			`;

      if (window.$(window).width() <= 1100) {
        elements += `<img src="${response.data.url}" class="magazine-image-size-mobile" />`;
      }

      response.data.elements.map((element, key) => {
        elements += `
				<a 
				  href="${element.url} "
				  target="_blank" 
				  class="magazine-link"
				  style="position: absolute; border-radius: ${
            element.type === "rectangle" ? "0" : "50%"
          }; top: ${element.top}%; left: ${element.left}%; width: ${
          element.width
        }%; height: ${element.height}%"
				>
				  	<span class="material-icons"> link </span> 
				</a>`;
      });

      elements += `
			</div>
			`;

      if (window.$(window).width() > 1100) {
        window.$("#flipbook").turn("addPage", elements, page);
      } else {
        window.$("#flipbook").slick("slickAdd", elements);
      }

      _getMagazine(magazine, page + 1, dispatch, getState);

      // dispatch({ type: SET_PAGE, payload: {magazine: magazine, pages: pages}, saving: false });
    })
    .catch(function (error) {
      // alert('Error, '+ error)
      // dispatch({ type: SET_PAGE, payload: {saving: false } });
    });
};

export const getMagazine = (magazine, page) => (dispatch, getState) => {
  _getMagazine(magazine, page, dispatch, getState);
};

export const savePages = () => (dispatch, getState) => {
  dispatch({ type: SET_PAGE, payload: { saving: true } });
  let magazine = getState().editor.magazine;
  let pages = getState().editor.pages;
  axios
    .post("https://api.revista.pedidosfarma.com/node/api/hook/processPDF", {})
    .then((resp) => {
      console.log("Generando PDF...");
    })
    .catch((err) => {
      console.log("Error al generar Revista");
    });
  axios
    .patch(`/magazines/${magazine}/`, { pages: JSON.stringify(pages) })
    .then(function (response) {
      console.log("response", response.data);
      dispatch({ type: SET_PAGE, payload: { saving: false } });
    })
    .catch(function (error) {
      dispatch({ type: SET_PAGE, payload: { saving: false } });
      alert("Error, " + error);
    });
};

export const setObject = (type) => (dispatch, getState) => {
  let elements = getState().editor.page.elements;
  let currentObject = getState().editor.object;
  let object = {
    id: elements.length,
    type: type,
    startX: 0,
    startY: 0,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    url: "",
  };

  if (currentObject && currentObject.type === type) object = null;

  dispatch({ type: SET_OBJECT, payload: { object: object } });
  dispatch({
    type: SET_MOUSE,
    payload: { mousePressed: false, selected: false },
  });
};

export const changeObject = (object) => (dispatch, getState) => {
  let page = getState().editor.page;

  page.elements.forEach((item, index) => {
    if (item === object)
      dispatch({ type: SET_OBJECT, payload: { object: item, selected: true } });
  });

  if (!object)
    dispatch({
      type: SET_OBJECT,
      payload: { object: null, selected: false, mousePressed: false },
    });
};

export const updateObject = (data) => (dispatch, getState) => {
  let page = getState().editor.page;
  let object = getState().editor.object;
  let _elements = [];
  page.elements.forEach((item, index) => {
    if (item.id == data.id) item[data.key] = data.value;
    _elements.push(item);
    object[data.key] = data.value;
  });
  page.elements = _elements;
  dispatch({ type: SET_OBJECT, payload: { object: object, page: page } });
};

export const removeObject = (object) => (dispatch, getState) => {
  let page = getState().editor.page;
  let currentObject = getState().editor.object;
  let _elements = [];
  page.elements.forEach((item, index) => {
    if (item !== object) _elements.push(item);
  });
  page.elements = _elements;
  dispatch({ type: REMOVE_OBJECT, payload: { object: null, page: page } });

  dispatch({
    type: SET_MOUSE,
    payload: { mousePressed: false, selected: false },
  });
};

export const setMouseDown = (mouse) => (dispatch, getState) => {
  let object = getState().editor.object;
  let top = mouse.clientY - mouse.target.offsetParent.offsetTop;
  let left = mouse.clientX - mouse.target.offsetParent.offsetLeft;
  // let startX = object.left
  // let startY = object.top
  object._top = top;
  object._left = left;
  object.top = pixelToPercentageY(top);
  object.left = pixelToPercentageX(left);
  // object.startX = pixelToPercentageX(startX)
  // object.startY = pixelToPercentageX(startY)

  dispatch({ type: SET_OBJECT, payload: { object: object } });
  dispatch({ type: SET_MOUSE, payload: { mousePressed: true } });
};

export const setMouseMove = (mouse) => (dispatch, getState) => {
  let object = getState().editor.object;
  let width =
    mouse.clientX - mouse.target.offsetParent.offsetLeft - object._left;
  let height =
    mouse.clientY - mouse.target.offsetParent.offsetTop - object._top;
  object.width = pixelToPercentageX(width);
  object.height = pixelToPercentageY(height);

  dispatch({ type: SET_OBJECT, payload: { object: object } });
};

export const setMouseUp = (e) => (dispatch, getState) => {
  let object = getState().editor.object;
  let page = getState().editor.page;

  page.elements.push(object);
  // dispatch({ type: SET_OBJECT, payload: {elements: elements, object: {...object, top: 0, left: 0, width: 0, height: 0}} });
  dispatch({ type: SET_OBJECT, payload: { page: page, object: null } });

  dispatch({
    type: SET_MOUSE,
    payload: { mousePressed: false, popover: true },
  });
};

export const setPage = (page) => (dispatch, getState) => {
  dispatch({ type: TOGGLE_MODAL_FILES, payload: { page: page } });
};

export const removePage = (id) => (dispatch, getState) => {
  let pages = getState().editor.pages;
  let currentPage = getState().editor.page;

  let _pages = [];
  pages.forEach((page, index) => {
    if (page.id !== id) _pages.push(page);
  });

  if (currentPage.id === id) currentPage = "";

  dispatch({
    type: TOGGLE_MODAL_FILES,
    payload: { pages: _pages, page: currentPage },
  });
};

export const setFiles = (e) => async (dispatch, getState) => {
  let pages = getState().editor.pages;
  let files = e.target.files;
  var arr = [];

  for (var i = 0; i < files.length; i++) {
    var reader = new FileReader();
    reader.readAsDataURL(files[i]);
    reader.onloadend = function (evt) {
      arr.push(evt.target.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  var interval = setInterval(function () {
    if (arr.length === files.length) {
      arr.forEach((page, key) => {
        pages.push({
          id: pages.length + key,
          url: page,
          elements: [],
        });
      });
      dispatch({ type: SET_PAGE, payload: { pages: pages } });
      clearInterval(interval);
    }
  }, 1000);
};
