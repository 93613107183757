import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { getMagazine } from "../../redux/modules/editor";
import './Magazine.scss'

class Turn extends React.Component {
    static defaultProps = {
      style: {},
      className: "",
      options: {}
    };
  
    componentDidMount() {
      let self = this;
      if (this.el && window.$(window).width() > 1100) {
        window.$(this.el).turn(Object.assign({}, this.props.options)).turn("pages", 5);
      } 
      
      if (window.$(window).width() <= 1100) {
				window.$('#flipbook').slick({
					arrows: false,
					dots: false
				});
      }
      document.addEventListener("keydown", this.handleKeyDown, false);

      // window.$("#flipbook").bind("turning", function(event, page, view) {
      //   self.props.nextPage(page)
      //   self.props.nextPage(page + 1)
      // });

      window.mouse = null
      document.body.onmousedown = function(e) { 
        window.mouse = e
      }
      document.body.onmouseup = function() {
        window.mouse = null
      }
    }
  
    componentWillUnmount() {
      if (this.el && window.$(window).width() > 1100) {
        window.$(this.el).turn("destroy").remove();
      }
      document.removeEventListener("keydown", this.handleKeyDown, false);
    }
  
    handleKeyDown = (event) => {
      if (event.keyCode === 37 && window.$(window).width() > 1100) {
        window.$(this.el).turn("previous");
      }
      if (event.keyCode === 39 && window.$(window).width() > 1100) {
        window.$(this.el).turn("next");
      }
    };
  
    render() {
      return (
        <div id="flipbook-move">
          <div
            id="flipbook"
            className={this.props.className}
            style={Object.assign({}, this.props.style)}
            ref={(el) => (this.el = el)}
          >
            {this.props.children}
          </div>
        </div>
      );
    }
}

class Magazine extends Component {
    constructor(props) {
        super(props)
        this.state = {
          id: null,
          page: 1,
          width: window.$(window).width(),
          height: window.$(window).height(),
          zoom: 1
        }
        this.fullscreen = this.fullscreen.bind(this);
        this.isFullScreen = this.isFullScreen.bind(this);
        this.zoom = this.zoom.bind(this);
    }

    componentDidMount() {
        const self = this;

        this.props.getMagazine(this.props.match.params.id, 1)
        // setTimeout(() => {self.props.getMagazine(self.props.match.params.id, 2)}, 1000)
        // setTimeout(() => {self.props.getMagazine(self.props.match.params.id, 3)}, 2000)

        var interval = setInterval(function() {
          if(window.$(window).width() > 1100) {
            var img = document.querySelector('.magazine-image-size')
            if(img) self.setState({width: img.width * 2, height: img.height})
            if(img) window.$("#flipbook").turn("size", img.width * 2, img.height);
            if(self.props.match.params.id) self.setState({id: self.props.match.params.id})
            if(img) clearInterval(interval)
          }
        },100)

        window.addEventListener('resize', function() {
          // window.location.reload()
          // window.$("#flipbook").size("resize");
        })
    }

    isFullScreen() {
      return (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);
    }

    fullscreen() {
      var isInFullScreen = this.isFullScreen()
      var self = this;

      var docElm = document.documentElement;
      if (!isInFullScreen) {
          if (docElm.requestFullscreen) {
              docElm.requestFullscreen();
          } else if (docElm.mozRequestFullScreen) {
              docElm.mozRequestFullScreen();
          } else if (docElm.webkitRequestFullScreen) {
              docElm.webkitRequestFullScreen();
          } else if (docElm.msRequestFullscreen) {
              docElm.msRequestFullscreen();
          }
          document.querySelector('.magazine-fullscreen').innerHTML = '<span class="material-icons">fullscreen_exit</span>';
      } else {
          if (document.exitFullscreen) {
              document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
          }
          document.querySelector('.magazine-fullscreen').innerHTML = '<span class="material-icons">fullscreen</span>';
      }

      var interval = setInterval(function() {
        if(window.$(window).width() > 1100) {
          var img = document.querySelector('.magazine-image-size')
          if(img) self.setState({width: img.width * 2, height: img.height})
          if(img) window.$("#flipbook").turn("size", img.width * 2, img.height);
          if(self.props.match.params.id) self.setState({id: self.props.match.params.id})
          if(img) clearInterval(interval)
        }
      },100)
    }

    zoom(zoom) {
      try {
        let newZoom = this.state.zoom + zoom;
        if(newZoom === 1 || zoom === 1) {
          window.$('#flipbook').css({top: 0, left: 0})
          window.$( "#flipbook" ).draggable('destroy');
          if(zoom === 1) newZoom = 1;
        } else {
          window.$( "#flipbook" ).draggable();
        }
        window.$("#flipbook").turn("zoom", newZoom);
        this.setState({zoom: newZoom })
      } catch(err) {
        
      }
    }

    render() {
        const { pages } = this.props.editor;

        const options = {
            display: "double",
            acceleration: true,
            gradients: !window.$.isTouch,
            elevation: 50,
            when: {
              turned: function (e, page) {
                console.log("Current view: ", window.$(this).turn("view"));
              }
            }
        };

        return (
            <Fragment>
              {pages.length ? <img src={pages[0].url} className="magazine-image-size" /> : null}
              <div className="magazine-conteint">
                {/* <div className="magazine-control magazine-fullscreen" onClick={() => this.fullscreen()}>
                  <span className="material-icons">fullscreen</span>
                </div> */}

                <div className="magazine-control-zoom">
                  <div className="magazine-control" onClick={() => this.zoom(+0.1)}>
                    <span class="material-icons"> zoom_in </span>
                  </div>
                  <div className="magazine-control" onClick={() => this.zoom(-0.1)}>
                    <span class="material-icons"> zoom_out </span>
                  </div>
                  <div className="magazine-control" onClick={() => this.zoom(1)} style={{opacity: this.state.zoom !== 1 ? 1 : 0.3, cursor: this.state.zoom !== 1 ? 'pointer' : 'default'}}>
                    <span class="material-icons"> fit_screen </span>
                  </div>
                </div>

                <div>
                  {this.state.width && this.state.height ?
                  <Turn options={options} pages={pages} className="magazine" style={{width: this.state.width, height: this.state.height}} nextPage={(page) => this.props.getMagazine(this.state.id, page)}>
                    {window.$(window).width() > 1100 ? <div></div> : null }
                    {/* {pages.map((page, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundImage: `url(${page.url})`
                            }}
                            className="magazine-image"
                        >
                          {page.elements.map((element, key) => {
                            return <a 
                              key={key} 
                              href={element.url} 
                              target="_blank" 
                              style={{
                                position: 'absolute',
                                borderRadius: element.type === 'rectangle' ? 0 : '50%', 
                                top: `${element.top}%`, 
                                left: `${element.left}%`, 
                                width: `${element.width}%`, 
                                height: `${element.height}%`
                              }} />
                          })}
                        </div>
                    ))} */}
                    </Turn>
                  :null}

                  {window.$(window).width() > 1100 ?
                  <>
                    <div className="magazine-control magazine-control-left prev" onClick={() => window.$('#flipbook').turn('previous')}><span className="material-icons"> keyboard_arrow_left </span></div>
                    <div className="magazine-control magazine-control-right next" onClick={() => window.$('#flipbook').turn('next')}><span className="material-icons"> keyboard_arrow_right </span></div>
                  </>
                  :
                  <>
                    <div className="magazine-control magazine-control-left prev" onClick={() => window.$('#flipbook').slick('slickPrev')}><span className="material-icons"> keyboard_arrow_left </span></div>
                    <div className="magazine-control magazine-control-right next" onClick={() => window.$('#flipbook').slick('slickNext')}><span className="material-icons"> keyboard_arrow_right </span></div>
                  </>
                  }
                </div>
              </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { editor } = state;
    return {
        editor
    };
}

function mapDistpachToProps(dispatch) {
    return bindActionCreators(
        {
            getMagazine
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDistpachToProps)(withRouter(Magazine));
