import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    Button, Popover, PopoverHeader, PopoverBody, Input, FormGroup, Label
  } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Shape.scss';

export default class Shape extends Component {
    constructor(props) {
        super(props)
        this.state = {
            popover: false
        }
    }
    render() {
        const { top, left, width, height, zIndex, type, id, url, onClick, onChange, onDelete, tools, selected, mousePressed } = this.props;
        return (
            top || left || width || height ?
            <>
                <div 
                    id={id}
                    className={`shape ${type} ${tools ? 'tools' : ''} ${selected ? 'selected' : ''}`}
                    style={{
                        top: `${top}%`, 
                        left: `${left}%`, 
                        width: `${width}%`,
                        height: `${height}%`,
                        zIndex: zIndex
                    }}
                >
                    <div className="shape-area" onClick={onClick}></div>

                    {tools && !mousePressed && selected ?
                        <>
                            <div className="shape-buttons">
                                <div className="shape-button">
                                    <span className="material-icons" onClick={() => this.setState({popover: !this.state.popover})}> link </span>
                                    <div className={`shape-popover ${this.state.popover ? 'show' : 'hide'}`}>
                                        <Input type="url" name="url" placeholder="URL de destino" value={url} onChange={(e) => onChange({id: id, key: 'url', value: e.target.value})} />
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="target_blank" defaultChecked={true} />
                                                Target Blank
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="shape-button" onClick={onDelete}>
                                    <span className="material-icons"> delete_outline </span>
                                </div>
                            </div>
                        </>
                    :null}
                
                </div>
            </>
            : null
        );
    }
}