import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setFiles, savePages } from "../../redux/modules/editor";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    Button
  } from 'reactstrap';
  
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.scss';

class Header extends Component {
    constructor(props) {
        super(props)
        this.inputFile = createRef()
    }
    render() {
        const { setFiles, savePages, saving, magazine } = this.props;
        return (
            <Navbar color="light" light expand="md">
                <NavbarBrand>Smart Magazine</NavbarBrand>
                <NavbarToggler />
                <Collapse navbar>
                    <Nav className="mr-auto" navbar>
                        <Button color="secondary mr-1 ml-3 d-flex" onClick={() => this.inputFile.current.click()}><span class="material-icons mr-1"> add_to_photos </span> Añandir Imagen</Button>
                        <input 
                            type="file"
                            multiple="multiple"
                            accept="image/x-png,image/gif,image/jpeg" 
                            ref={this.inputFile} 
                            style={{position: 'absolute', visibility: 'hidden'}} 
                            onChange={setFiles}
                        />
                    </Nav>
                    <Nav>
                        <Button color="secondary mr-1 d-flex" onClick={() => savePages()}><span class="material-icons mr-1"> save </span> {!saving ? 'Guardar' : 'Aguarde...'}</Button>
                        <Button color="primary d-flex" onClick={() => window.open(`/magazine/${this.props.match.params.id}`, "_blank").focus()}><span class="material-icons mr-1"> launch </span> Visualizar</Button>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

function mapStateToProps(state) {
    const { editor } = state;
    return {
        editor
    };
}

function mapDistpachToProps(dispatch) {
    return bindActionCreators(
        {
            setFiles,
            savePages
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDistpachToProps)(withRouter(Header));
