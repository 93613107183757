import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Editor from './containers/Editor/Editor';
import Magazine from './containers/Magazine/Magazine';

export default function Routers() {
  return (
    <Switch>
      <Route exact path="/editor/:id">
        <Editor />
      </Route>
      <Route exact path="/magazine/:id">
        <Magazine />
      </Route>
    </Switch>
  );
}