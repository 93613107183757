import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { setObject, removeObject, setMouseUp, setMouseDown, setMouseMove, changeObject, updateObject, setPage, setMagazine, removePage } from "../../redux/modules/editor";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
  } from 'reactstrap';
import Header from '../../components/Header/Header';
import Shape from '../../components/Shape/Shape'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './Editor.scss'

class Editor extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.setMagazine(this.props.match.params.id)
    }
    
    render() {
        const { setObject, removeObject, setMouseUp, setMouseDown, setMouseMove, changeObject, updateObject, setPage, removePage } = this.props;
        const { mousePressed, elements, object, selected, pages, page } = this.props.editor;
        return (
            <Fragment>
                <Header />
                <div className="editor">
                    <div className="editor-sidebar">
                        {pages.map((_page, key) => {
                            return (
                                <div key={key} className={`editor-image-box ${_page.id === page.id ? 'active' : ''}`} >
                                    <img src={_page.url} onClick={(e) => setPage(_page) } className="editor-image" draggable={false} />
                                    <span className="editor-image-counter">{key+1}</span>
                                    <div className="editor-image-delete" onClick={(e) => removePage(_page.id) }><span className="material-icons"> delete_outline </span></div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="editor-content">
                        <div className="editor-toolbars">
                            <div className="editor-toolbars-col">
                                <div className="editor-toolbars-group">
                                    <div className={`editor-toolbars-button ${object && mousePressed && object.type === 'rectangle' ? 'active' : ''}`} onClick={() => setObject('rectangle')}><span className="material-icons">crop_din</span></div>
                                    <div className={`editor-toolbars-button ${object && mousePressed && object.type === 'circle' ? 'active' : ''}`} onClick={() => setObject('circle')}><span className="material-icons">radio_button_unchecked</span></div>
                                    {/* <div className={`editor-toolbars-button ${object && object.type === 'triangle' ? 'active' : ''}`} onClick={() => setObject('triangle')}><span className="material-icons">change_history</span></div> */}
                                </div>
                            </div>
                            <div className="editor-toolbars-col ml-auto">
                                <div className="editor-toolbars-group">
                                    {object ?
                                        <div className="editor-toolbars-button" onClick={() => removeObject(object)}><span className="material-icons">delete_outline</span></div>
                                    :null}
                                </div>
                            </div>
                        </div>
                        <div>{object?<p>top: {object.top}, left: {object.left}, width: {object.width}, height: {object.height}, startX: {object.startX}, startY: {object.startY}</p>:null}</div>
                        <div className="editor-window">
                            <div className="editor-image-area"
                                onMouseDown={(e) => object && !selected ? setMouseDown(e) : null} 
                                onMouseMove={(e) => mousePressed && !selected ? setMouseMove(e) : null} 
                                onMouseUp={(e) => object && !selected ? setMouseUp(e) : null}
                            >
                                {page?
                                <img
                                    src={page.url} 
                                    className="editor-image" 
                                    draggable={false} 
                                    style={{
                                        cursor: object && mousePressed ? 'crosshair' : 'default'
                                    }}
                                    onClick={() => selected ? changeObject(null) : null}
                                />
                                :null}
                                
                                <Shape 
                                    {...object}
                                    show={object}
                                    tools={true}
                                    zIndex={9999}
                                    selected={selected}
                                    mousePressed={mousePressed}
                                    onDelete={() => removeObject(object)}
                                    onChange={(data) => updateObject(data)}
                                />

                                {page ? page.elements.map((item, key) => {
                                    return (
                                        item !== object ?
                                        <Shape
                                            {...item}
                                            key={key}
                                            zIndex={key}
                                            onClick={() => changeObject(item)}
                                        />
                                        : null
                                    )
                                }) : null}
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { editor } = state;
    return {
        editor
    };
}

function mapDistpachToProps(dispatch) {
    return bindActionCreators(
        {
            setObject,
            removeObject,
            setMouseUp,
            setMouseDown,
            setMouseMove,
            changeObject,
            updateObject,
            setPage,
            removePage,
            setMagazine
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDistpachToProps)(withRouter(Editor));
